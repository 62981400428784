import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import LogoxMoney from 'common/components/LogoxMoney';
import colors from 'common/styles/colors';

import styles from './index.module.css';

const UnsupportedCountry = ({ isSubmitting, isSubmitted, onNotifyClick }) => (
  <div className={styles.root} data-test="unsupported-country-page">
    <div className={styles.logoContainer}>
      <LogoxMoney />
    </div>
    <Heading level="1" size="1" color="blue">
      Unsupported country
    </Heading>
    <div className={styles.messageContainer}>
      <Text color="rebranding-md-color-grey-60">
        Unfortunately, we currently don&apos;t support your country of
        registration.
      </Text>
      <Text color="rebranding-md-color-grey-60">
        We are actively working to expand our services.
      </Text>
    </div>
    <Text color="rebranding-color-black">
      If you choose to, we&apos;ll get in touch as soon as this changes
    </Text>
    <div className={styles.buttonContainer}>
      <Button
        kind="primary"
        name="notify-me"
        full
        loading={isSubmitting}
        disabled={isSubmitting || isSubmitted}
        onClick={onNotifyClick}
      >
        <button type="button">
          {isSubmitting ? 'Submitting...' : 'I want to be notified'}
        </button>
      </Button>
    </div>
    {isSubmitted && (
      <div className={styles.emailRegistered}>
        <Icon.CheckCircleOutline
          fill={colors['rebranding-md-color-green']}
          width={20}
          height={20}
        />
        Your email has been registered
      </div>
    )}
  </div>
);

UnsupportedCountry.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  onNotifyClick: PropTypes.func.isRequired,
};

export default UnsupportedCountry;
