import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import LogoxMoney from 'common/components/LogoxMoney';
import Icon from 'common/components/Icon';
import {
  requiredDocumentsUrl,
  sandboxUrl,
  productionUrl,
} from 'common/constants';

import styles from './index.module.css';

const Title = ({ heading }) => (
  <>
    <div className={styles.logoWrapper}>
      <LogoxMoney />
    </div>
    <Heading level="1" size="1" color="blue">
      {heading}
    </Heading>
  </>
);

Title.propTypes = {
  heading: PropTypes.string.isRequired,
};

const Description = () => (
  <>
    <Title heading="Accept crypto payments in your business bank account" />
    <div className={styles.headingWrapper}>
      <Heading level="3" size="3" color="grey-60">
        It takes under 5 minutes to provide documentation and complete your
        business registration
      </Heading>
    </div>
    <div className={styles.buttonWrapper}>
      <Button kind="secondary" full>
        <a
          href={requiredDocumentsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Review required documents
          <Icon.ExternalLink2
            width={20}
            height={20}
            className={classNames(
              styles.externalLinkIcon,
              styles.buttonExternalLinkIcon
            )}
            size="medium"
          />
        </a>
      </Button>
    </div>
    <div className={styles.separator} />
    <p className={styles.sandboxLinkText}>
      Or test everything before you commit
    </p>
    <a
      className={styles.sandboxLink}
      href={sandboxUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      Explore in test mode
      <Icon.ExternalLink2
        width={20}
        height={20}
        className={styles.externalLinkIcon}
        size="medium"
      />
    </a>
  </>
);

const DescriptionSandbox = () => (
  <>
    <Title heading="Welcome to the xMoney sandbox environment" />
    <div className={styles.productionLinkWrapper}>
      <p className={styles.sandboxLinkText}>Ready to go live?</p>
      <a
        className={styles.sandboxLink}
        href={productionUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to production
        <Icon.ExternalLink2
          width={20}
          height={20}
          className={styles.externalLinkIcon}
          size="medium"
        />
      </a>
    </div>
  </>
);

export { Description, DescriptionSandbox };
