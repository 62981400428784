import React, { useState } from 'react';
import { connect } from 'react-redux';

import { actions as errorActions } from 'error';
import { submitNotificationRequest } from 'app/services/api';
import { selectors as sessionSelectors } from 'session';
import UnsupportedCountryComponent from '../components/UnsupportedCountry';

const UnsupportedCountry = ({ apiError, notifyOnSupportCountry }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleNotifyClick = async () => {
    setIsSubmitting(true);

    const { error } = await submitNotificationRequest(true);

    if (error) {
      apiError(error);
      return;
    }

    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  return (
    <UnsupportedCountryComponent
      isSubmitting={isSubmitting}
      isSubmitted={notifyOnSupportCountry || isSubmitted}
      onNotifyClick={handleNotifyClick}
    />
  );
};

export default connect(
  (state) => ({
    notifyOnSupportCountry:
      sessionSelectors.activeOrganization(state)?.notifyOnSupportCountry,
  }),
  {
    apiError: errorActions.apiError,
  }
)(UnsupportedCountry);
