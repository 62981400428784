import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { selectors as featuresSelectors } from 'features';
import { selectors as sessionSelectors } from 'session';
import { containers as ErrorContainer } from 'error';

import AppContainer from 'common/components/AppContainer';
import AnnouncementBanner from 'common/components/AnnouncementBanner';
import EnvironmentBanner from 'common/components/EnvironmentBanner';
import AccountStatusBanner from 'common/components/AccountStatusBanner';
import { kybStarted, signedUp, kybBaseInfoSubmitted } from 'common/constants';
import Router from './Router';
import { history } from '../services';

const App = ({
  isBannerAnnouncementActive,
  complianceStatus,
  integrationStatus,
  kybInProgress,
}) => {
  const env = process.env.REACT_APP_ENV || 'local';

  const [hasDismissedBanner, setHasDismissedBanner] = useState(
    localStorage.getItem('dismissedAnnouncementBanner')
  );

  const dismissAnnouncementBanner = () => {
    localStorage.setItem('dismissedAnnouncementBanner', true);
    setHasDismissedBanner(true);
  };

  const renderEnvironmentBanner = () => {
    if (env === 'production') return;

    return <EnvironmentBanner env={env} />;
  };

  const renderAnnouncementBanner = () => {
    if (!isBannerAnnouncementActive || hasDismissedBanner) return;

    return (
      <AnnouncementBanner env={env} onDismiss={dismissAnnouncementBanner} />
    );
  };

  const renderAccountStatusBanner = () => {
    if (env === 'sandbox') return null;

    // not confirmed their mail or the initial form is not submitted
    if (integrationStatus === signedUp || complianceStatus === kybStarted) {
      return;
    }

    const readyToVerify = complianceStatus === kybBaseInfoSubmitted;

    const getStatus = () => {
      if (readyToVerify) return 'verify';
      if (kybInProgress) return 'verifyInProgress';

      return null;
    };

    const status = getStatus();

    if (!status) return null;

    return <AccountStatusBanner status={status} />;
  };

  return (
    <AppContainer>
      <ConnectedRouter history={history}>
        {renderEnvironmentBanner()}
        {renderAnnouncementBanner()}
        {renderAccountStatusBanner()}
        <ErrorContainer>
          <Router />
        </ErrorContainer>
      </ConnectedRouter>
    </AppContainer>
  );
};

export default connect((state) => ({
  isBannerAnnouncementActive:
    featuresSelectors.isBannerAnnouncementActive(state),
  complianceStatus:
    sessionSelectors.activeOrganization(state)?.complianceStatus,
  integrationStatus:
    sessionSelectors.activeOrganization(state)?.integrationStatus,
  kybInProgress: sessionSelectors.activeOrganization(state)?.kybInProgress,
}))(App);
