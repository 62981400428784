import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';

import { track } from 'common/analytics';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Checkbox from 'common/components/Checkbox';
import TextBox from 'common/components/TextBox';
import ValidationError from 'common/components/ValidationError';
import formUtils, { SIMPLE_PASSWORD_MESSAGE } from 'common/formUtils.js';
import PasswordLabel from 'common/components/PasswordLabel';
import Text from 'common/components/Text';
import Dropdown from 'common/components/Dropdown';
import Label from 'common/components/Label';

import styles from './index.module.css';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  businessCountry: '',
  sendNewsletter: [],
  termsAndConditions: [],
};

const queryValues = (location) => queryString.parse(location.search);

const Form = ({
  onSubmit,
  submitting,
  countries,
  isFetchingCountries,
  recaptchaRef,
}) => {
  const location = useLocation();
  const prePopulatedValues = queryValues(location);
  const passwordField = useRef(null);

  return (
    <Formik
      initialValues={{ ...initialValues, ...prePopulatedValues }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        values,
      }) => {
        const handleLabelClick = () => {
          if (values.termsAndConditions.length)
            setFieldValue('termsAndConditions', []);
          else setFieldValue('termsAndConditions', ['on']);
        };

        return (
          <form onSubmit={handleSubmit}>
            <Heading level="3" size="3" weight="medium" id="store-information">
              Register your business
            </Heading>
            <br />
            <div className={styles.row}>
              <div className={styles.columns}>
                <Field name="firstName" validate={formUtils.validateName}>
                  {({ field, meta }) => (
                    <div className={styles.column}>
                      <TextBox
                        id="firstName"
                        labelText="First name"
                        labelType="firstName"
                        placeholder="Your given name"
                        error={meta.touched && meta.error}
                        errorText={meta.error}
                        {...field}
                      />
                    </div>
                  )}
                </Field>
                <Field name="lastName" validate={formUtils.validateName}>
                  {({ field, meta }) => (
                    <div className={styles.column}>
                      <TextBox
                        id="lastName"
                        labelText="Last name"
                        labelType="lastName"
                        placeholder="Your family name"
                        error={meta.touched && meta.error}
                        errorText={meta.error}
                        {...field}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <Field name="email" validate={formUtils.validateEmail}>
              {({ field, meta }) => (
                <div className={styles.row}>
                  <TextBox
                    id="email"
                    labelText="Email"
                    labelType="email"
                    placeholder="Your email address"
                    autoComplete="new-email"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    {...field}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value.trim());
                    }}
                  />
                </div>
              )}
            </Field>
            <Field name="businessCountry" validate={formUtils.validateRequired}>
              {({ meta }) => (
                <>
                  <div className={styles.row}>
                    <Label>Business country</Label>
                    {isFetchingCountries ? (
                      'Loading...'
                    ) : (
                      <Dropdown
                        isSearchable
                        id="businessCountry"
                        initialSelectedItem={meta.initialValue}
                        name="businessCountry"
                        placeholder="Choose your country"
                        items={countries}
                        error={!!(meta.touched && meta.error)}
                        errorText={meta.error}
                        onChange={(value) => {
                          setFieldValue('businessCountry', value, true);
                        }}
                      />
                    )}

                    {!(meta.error && meta.touched) && (
                      <div className={styles.inputHelper}>
                        <Text
                          size="caption"
                          color="rebranding-md-color-grey-60"
                        >
                          This is the country your company is registered in.
                        </Text>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Field>
            <Field name="password" validate={formUtils.validatePassword}>
              {({ field, meta }) => (
                <>
                  {passwordField && (
                    <PasswordLabel
                      customLabel="Password (8 or more characters)"
                      input={passwordField}
                    />
                  )}
                  <div className={styles.row}>
                    <TextBox
                      id="password"
                      name="password"
                      type="password"
                      labelType="password"
                      placeholder="Your password"
                      autoComplete="new-password"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      inputRef={passwordField}
                      {...field}
                    />
                    {!(meta.error && meta.touched) && (
                      <div className={styles.inputHelper}>
                        <Text
                          size="caption"
                          color="rebranding-md-color-grey-60"
                        >
                          {SIMPLE_PASSWORD_MESSAGE}
                        </Text>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Field>
            <Field name="sendNewsletter">
              {({ meta }) => (
                <div className={styles.row}>
                  <Checkbox
                    label="I want to receive product updates and information about campaigns"
                    name="sendNewsletter"
                    id="sendNewsletter"
                    checked={meta.value}
                    value={formUtils.checkBoxValue(meta.value)}
                    onChange={(e) => {
                      if (e.target.value === 'on' && window.analytics) {
                        track('Product Updates checkbox checked');
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              )}
            </Field>
            <Field
              name="termsAndConditions"
              validate={formUtils.validateRequired}
            >
              {({ meta }) => (
                <div className={styles.row}>
                  <Checkbox
                    label=""
                    name="termsAndConditions"
                    id="termsAndConditions"
                    checked={meta.value}
                    value={formUtils.checkBoxValue(meta.value)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span
                    role="button"
                    tabIndex="0"
                    className={styles.termsAndConditionsLabel}
                    onClick={handleLabelClick}
                    onKeyDown={({ key }) => key === 'Enter' && handleLabelClick}
                  >
                    I accept the{' '}
                    <a
                      href="https://xmoney.com/legal-acknowledgement"
                      className={styles.termsAndConditionsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of service
                    </a>
                  </span>
                  {meta.touched && meta.error && (
                    <span className={styles.checkboxError}>
                      <ValidationError
                        errorString={meta.error}
                        id="termsAndConditions"
                      />
                    </span>
                  )}
                </div>
              )}
            </Field>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
            />
            <span className={styles.formFooter}>
              <Text>
                <p>Already have an account?</p>
                <p>
                  <Link className={styles.termsAndConditionsLink} to="/sign-in">
                    Sign in
                  </Link>
                </p>
              </Text>
              <div className={styles.buttonWrapper}>
                <Button
                  full
                  kind="primary"
                  loading={submitting}
                  disabled={formUtils.isSubmitDisabled(errors, touched)}
                >
                  <button
                    type="submit"
                    id="progress-status"
                    data-test="sign-up-button"
                  >
                    Create account
                  </button>
                </Button>
              </div>
            </span>
          </form>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isFetchingCountries: PropTypes.bool.isRequired,
  recaptchaRef: PropTypes.shape({}).isRequired,
};

export default Form;
