import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import actions from '../actions';

const {
  ORGANIZATIONS_FETCHING,
  ORGANIZATIONS_REQUEST,
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_FAILURE,
  STORES_REQUEST,
  STORES_SUCCESS,
  STORES_FAILURE,
  SESSION_LOAD,
  SESSION_SUCCESS,
  SESSION_FAILURE,
  ACTIVE_ORGANIZATION,
  ACTIVE_STORE,
  ORGANIZATION_AND_STORE_CLEAR,
  ORGANIZATION_ONBOARDING_STATUS,
  ORGANIZATION_INTEGRATION_STATUS,
  ORGANIZATION_COMPLIANCE_STATUS,
  ORGANIZATION_BANK_INFO,
  ORGANIZATION_SETTLEMENT_CURRENCY,
  ORGANIZATION_KYB_IN_PROGRESS,
  ACTIVE_STORE_UPDATE,
} = actions;

const organizationsReducer = handleActions(
  {
    [ORGANIZATIONS_SUCCESS]: (state, { payload }) =>
      _.map(payload.organizations, (organization) => ({
        id: organization.id,
        name: organization.attributes.name,
        onboardingStatus: organization.attributes.onboardingStatus,
        integrationStatus: organization.attributes.integrationStatus,
        complianceStatus: organization.attributes.complianceStatus,
        isSuspended: organization.attributes.isSuspended,
        bankInfo: organization.attributes.bankInfo,
        notifyOnSupportCountry: organization.attributes.notifyOnSupportCountry,
        kybInProgress: organization.attributes.kybInProgress,
      })),
  },
  null
);

const storesReducer = handleActions(
  {
    [STORES_SUCCESS]: (state, { payload }) =>
      _.map(payload.stores, (store) => ({
        id: store.id,
        companyOfficialName: store.attributes.companyOfficialName,
        name: store.attributes.name,
        vatRequired: store.attributes.vatRequired,
        integrationType: store.attributes.integrationType,
        country: store.attributes.country,
      })),
  },
  null
);

const activeOrganizationReducer = handleActions(
  {
    [ACTIVE_ORGANIZATION]: (state, { payload }) => payload,
    [ORGANIZATION_AND_STORE_CLEAR]: () => null,
    [ORGANIZATION_ONBOARDING_STATUS]: (state, { payload }) => ({
      ...state,
      onboardingStatus: payload,
    }),
    [ORGANIZATION_INTEGRATION_STATUS]: (state, { payload }) => ({
      ...state,
      integrationStatus: payload,
    }),
    [ORGANIZATION_COMPLIANCE_STATUS]: (state, { payload }) => ({
      ...state,
      complianceStatus: payload,
    }),
    [ORGANIZATION_KYB_IN_PROGRESS]: (state, { payload }) => ({
      ...state,
      kybInProgress: payload,
    }),
    [ORGANIZATION_BANK_INFO]: (state, { payload }) => ({
      ...state,
      bankInfo: payload,
    }),
    [ORGANIZATION_SETTLEMENT_CURRENCY]: (state, { payload }) => ({
      ...state,
      bankInfo: {
        ...state.bankInfo,
        settlementCurrency: payload,
      },
    }),
  },
  null
);

const activeStoreReducer = handleActions(
  {
    [ACTIVE_STORE]: (state, { payload }) => payload,
    [ACTIVE_STORE_UPDATE]: (state, { payload }) => ({ ...state, ...payload }),
    [combineActions(ORGANIZATION_AND_STORE_CLEAR)]: () => null,
  },
  null
);

const isFetchingReducer = handleActions(
  {
    [combineActions(
      ORGANIZATIONS_REQUEST,
      STORES_REQUEST,
      ORGANIZATIONS_FETCHING
    )]: () => true,
    [combineActions(
      ORGANIZATIONS_SUCCESS,
      STORES_SUCCESS,
      ORGANIZATIONS_FAILURE,
      STORES_FAILURE
    )]: () => false,
  },
  false
);

const isSettingUpSessionReducer = handleActions(
  {
    [SESSION_LOAD]: () => true,
    [combineActions(SESSION_SUCCESS, SESSION_FAILURE)]: () => false,
  },
  false
);

export const organizations = (state) => state.session.organizations;
export const stores = (state) => state.session.stores;
export const isFetching = (state) => state.session.isFetching;
export const isSettingUpSession = (state) => state.session.isSettingUpSession;
export const activeStore = (state) => state.session.activeStore;
export const activeOrganization = (state) => state.session.activeOrganization;
export const settlementCurrency = (state) =>
  state.session?.activeOrganization?.bankInfo?.settlementCurrency;

export default combineReducers({
  organizations: organizationsReducer,
  stores: storesReducer,
  isFetching: isFetchingReducer,
  isSettingUpSession: isSettingUpSessionReducer,
  activeOrganization: activeOrganizationReducer,
  activeStore: activeStoreReducer,
});
