import React from 'react';
import PropTypes from 'prop-types';

import Layout from './Layout';
import Form from './Form';
import { Description, DescriptionSandbox } from './Description';

const SignUp = ({
  onSubmit,
  errorMessage,
  submitting,
  sandboxMode,
  recaptchaRef,
  countries,
  isFetchingCountries,
}) => {
  const DescriptionComp = sandboxMode ? DescriptionSandbox : Description;

  return (
    <Layout
      form={
        <Form
          onSubmit={onSubmit}
          submitting={submitting}
          recaptchaRef={recaptchaRef}
          countries={countries}
          isFetchingCountries={isFetchingCountries}
        />
      }
      description={<DescriptionComp />}
      error={errorMessage}
    />
  );
};

SignUp.propTypes = {
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  sandboxMode: PropTypes.bool,
  recaptchaRef: PropTypes.shape({}).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isFetchingCountries: PropTypes.bool.isRequired,
};

SignUp.defaultProps = {
  errorMessage: '',
  sandboxMode: false,
};

export default SignUp;
