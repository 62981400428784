import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'ORGANIZATIONS_FETCHING',
  'ORGANIZATIONS_REQUEST',
  'ORGANIZATIONS_SUCCESS',
  'ORGANIZATIONS_FAILURE',
  'STORES_REQUEST',
  'STORES_SUCCESS',
  'STORES_FAILURE',
  'SESSION_CLEAR',
  'SESSION_LOAD',
  'SESSION_SUCCESS',
  'SESSION_FAILURE',
  'ACTIVE_ORGANIZATION',
  'ACTIVE_STORE',
  'ACTIVE_STORE_UPDATE',
  'ORGANIZATION_AND_STORE_CLEAR',
  'ORGANIZATION_ONBOARDING_STATUS',
  'ORGANIZATION_INTEGRATION_STATUS',
  'ORGANIZATION_COMPLIANCE_STATUS',
  'ORGANIZATION_BANK_INFO',
  'ORGANIZATION_SETTLEMENT_CURRENCY',
  'ORGANIZATION_KYB_IN_PROGRESS',
]);

const actionCreators = createActions(
  {
    [actionTypes.ORGANIZATIONS_FAILURE]: Error,
    [actionTypes.STORES_FAILURE]: Error,
    [actionTypes.SESSION_FAILURE]: Error,
  },
  actionTypes.ORGANIZATIONS_FETCHING,
  actionTypes.ORGANIZATIONS_REQUEST,
  actionTypes.ORGANIZATIONS_SUCCESS,
  actionTypes.STORES_REQUEST,
  actionTypes.STORES_SUCCESS,
  actionTypes.SESSION_LOAD,
  actionTypes.SESSION_SUCCESS,
  actionTypes.SESSION_CLEAR,
  actionTypes.ACTIVE_ORGANIZATION,
  actionTypes.ACTIVE_STORE,
  actionTypes.ORGANIZATION_AND_STORE_CLEAR,
  actionTypes.ORGANIZATION_ONBOARDING_STATUS,
  actionTypes.ORGANIZATION_INTEGRATION_STATUS,
  actionTypes.ORGANIZATION_COMPLIANCE_STATUS,
  actionTypes.ORGANIZATION_BANK_INFO,
  actionTypes.ACTIVE_STORE_UPDATE,
  actionTypes.ORGANIZATION_SETTLEMENT_CURRENCY,
  actionTypes.ORGANIZATION_KYB_IN_PROGRESS
);

export default {
  ...actionTypes,
  ...actionCreators,
};
