import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import { settlementsRoute } from 'app/containers/routes';
import { sandboxUrl } from 'common/constants';

import styles from './index.module.css';

const statuses = {
  verify: {
    title: 'Limited Access!',
    message:
      'Verify your business to unlock the full potential of xMoney products.',
  },
  verifyInProgress: {
    title: 'Verifying information',
    message:
      'Usually 1-2 business days. Check your inbox for any further requests.',
  },
};

const AccountStatusBanner = ({ status }) => {
  const history = useHistory();

  const handleVerifyClick = () => {
    history.push(settlementsRoute, { showKyb: true });
  };

  const verifyButton = (
    <button
      type="button"
      data-test="banner-verify-business-button"
      className={styles.button}
      onClick={handleVerifyClick}
    >
      <Icon.ShieldCheck
        fill={colors['rebranding-md-color-orange-dark']}
        width={20}
        height={20}
      />
      Verify business
    </button>
  );

  const exploreButton = (
    <a
      type="button"
      data-test="banner-explore-button"
      className={styles.button}
      onClick={handleVerifyClick}
      href={sandboxUrl}
    >
      Explore in test mode
      <Icon.ExternalLink2
        fill={colors['rebranding-md-color-orange-dark']}
        width={20}
        height={20}
      />
    </a>
  );

  return (
    <div className={styles.root} data-test="account-status-banner">
      <div className={styles.content}>
        <div className={styles.main}>
          <div className={styles.status}>{statuses[status].title}</div>
          <span className={styles.dot}>•</span>
          <div className={styles.description}>{statuses[status].message}</div>
        </div>
        <div className={styles.cta}>
          {status === 'verify' && verifyButton}
          {status === 'verifyInProgress' && exploreButton}
        </div>
      </div>
    </div>
  );
};

AccountStatusBanner.propTypes = {
  status: PropTypes.string.isRequired,
};

export default AccountStatusBanner;
